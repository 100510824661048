// JsFromRoutes CacheKey cd3fe2af353111a7f60975ed93c72514
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/client'

export default {
  reenable: /* #__PURE__ */ definePathHelper('patch', '/profiles/:id/reenable'),
  index: /* #__PURE__ */ definePathHelper('get', '/profiles'),
  create: /* #__PURE__ */ definePathHelper('post', '/profiles'),
  update: /* #__PURE__ */ definePathHelper('patch', '/profiles/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/profiles/:id'),
}
