import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowDownAZ,
  faArrowRight,
  faAt,
  faBan,
  faBars,
  faBrazilianRealSign,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCloudArrowUp,
  faDollarSign,
  faEllipsisVertical,
  faEquals,
  faEuroSign,
  faFilter,
  faFloppyDisk,
  faGear,
  faHome,
  faKey,
  faLink,
  faList,
  faListOl,
  faMagnifyingGlass,
  faMagnifyingGlassChart,
  faMinus,
  faMoneyBillTransfer,
  faPenToSquare,
  faPlus,
  faRepeat,
  faRightFromBracket,
  faRightLeft,
  faRightToBracket,
  faRobot,
  faRotateLeft,
  faScaleBalanced,
  faShapes,
  faShareNodes,
  faScissors,
  faSliders,
  faSpinner,
  faSterlingSign,
  faSquareCheck,
  faSquareMinus,
  faSquarePlus,
  faTriangleExclamation,
  faUser,
  faUserPlus,
  faUserTag,
  faWallet,
  faWandMagicSparkles,
  faXmark,
  faYenSign,
} from '@fortawesome/free-solid-svg-icons';

import {
  faCircleCheck as farCircleCheck,
  faCircleQuestion as farCircleQuestion,
  faCircleXmark as farCircleXmark,
  faCreditCard as farCreditCard,
  faFaceFrown as farFaceFrown,
  faFloppyDisk as farFloppyDisk,
  faFolder as farFolder,
  faSquare as farSquare,
  faSquareMinus as farSquareMinus,
  faTrashCan as farTrashCan,
} from '@fortawesome/free-regular-svg-icons';

import {
  faGithub as fabGithub,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowDownAZ,
  faArrowRight,
  faAt,
  faBan,
  faBars,
  faBrazilianRealSign,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  farCircleCheck,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  farCircleQuestion,
  farCircleXmark,
  faCloudArrowUp,
  farCreditCard,
  faDollarSign,
  faEllipsisVertical,
  faEquals,
  faEuroSign,
  farFaceFrown,
  faFilter,
  faFloppyDisk,
  farFloppyDisk,
  farFolder,
  faGear,
  fabGithub,
  faHome,
  faKey,
  faLink,
  faList,
  faListOl,
  faMagnifyingGlass,
  faMagnifyingGlassChart,
  faMinus,
  faMoneyBillTransfer,
  faPenToSquare,
  faPlus,
  faRepeat,
  faRightFromBracket,
  faRightLeft,
  faRightToBracket,
  faRobot,
  faRotateLeft,
  faScaleBalanced,
  faShapes,
  faShareNodes,
  faScissors,
  faSliders,
  faSpinner,
  faSterlingSign,
  farSquare,
  faSquareCheck,
  faSquareMinus,
  farSquareMinus,
  faSquarePlus,
  farTrashCan,
  faTriangleExclamation,
  faUser,
  faUserPlus,
  faUserTag,
  faWallet,
  faWandMagicSparkles,
  faXmark,
  faYenSign,
);
